import React from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Container, Button, Card, CardDeck } from 'react-bootstrap';
import prices from '../prices';
import { products, assetLanguageMap, getLanguage } from '../App';
import { prefillItemList } from '../util/productUtils'
import { KonnektBranding } from "../components/KonnektBranding";
import { BrandingText } from "../components/BrandingText";
import { useParams } from 'react-router-dom';

export function ShopHome() {
  let props = useParams();
  prefillItemList();
  const { t } = useTranslation();

  return (
    <div className="page-shop-home">
      <Container>
        <h1 className="notBold">{t("shop-welcome-1")} <BrandingText /> <b><KonnektBranding /></b> {t("shop-welcome-2")}</h1>
        <h2 className="notBold size-h3">{t("shop-deliver-to")} {t(prices[props.region.toLowerCase()].name)}</h2>
      </Container>

      <Container>
        <CardDeck>
          {Object.keys(products).map(p => <Card>
            <Card.Body>
              <Card.Img src={products[p].image.replace("lang", assetLanguageMap[getLanguage()] ? getLanguage() : "en")} className="shopCardImg" aria-hidden="true"></Card.Img>
              <Card.Title><h3 class="card-title h5">{t("product-" + products[p].contentId + "-name")}</h3></Card.Title>
              <Card.Text><Trans i18nKey={"product-" + products[p].contentId + "-description"}></Trans></Card.Text>
              <a href={"/shop/" + props.region + "/" + products[p].contentId} className="stretched-link" title={t("product-" + products[p].contentId + "-name")}> </a>
            </Card.Body>
            <Card.Footer><Button href={"/shop/" + props.region + "/" + products[p].contentId}>{t("product-learn-more")}</Button></Card.Footer>
          </Card>
          )}
        </CardDeck>
      </Container>
    </div>);
}
