import React from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Container, Button, Form, Alert } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { FormRequiredText } from './FormRequiredText';
import { FormCheckHelper } from './FormCheckHelper';
import { getLanguage } from '../App';
import { Turnstile } from '@marsidev/react-turnstile'


export function ContactForm() {
  const { t } = useTranslation();
  return (
    <Container>
      <h3 className="text-center not-bold h5">{t("contact-title")}</h3>
      <br />
      <Form onSubmit={cfHandleSubmit}>
        <Form.Group id="formFName">
          <Form.Label htmlFor="js-form-fname">{t("contact-firstname")} {FormRequiredText()}</Form.Label>
          <Form.Control type="text" name="firstname" id="js-form-fname" required />
        </Form.Group>
        <Form.Group id="formLName">
          <Form.Label htmlFor="js-form-lname">{t("contact-lastname")} {FormRequiredText()}</Form.Label>
          <Form.Control type="text" name="lastname" id="js-form-lname" required />
        </Form.Group>
        <Form.Group id="formEmail">
          <Form.Label htmlFor="js-form-email">{t("contact-email")} {FormRequiredText()}</Form.Label>
          <Form.Control type="email" name="email" id="js-form-email" required />
        </Form.Group>
        <Form.Group id="formPhone">
          <Form.Label htmlFor="js-form-phone">{t("contact-phone")}</Form.Label>
          <Form.Control type="text" name="phone" id="js-form-phone" />
        </Form.Group>
        <Form.Label>{t("contact-lang")}</Form.Label>
        {FormCheckHelper("en")}
        {FormCheckHelper("de")}
        {FormCheckHelper("fr")}
        {FormCheckHelper("it")}
        {FormCheckHelper("nl")}
        {FormCheckHelper("es")}
        <Form.Group id="formOtherLang">
          <Form.Label htmlFor="js-form-otherlang">{t("contact-otherlang")}</Form.Label>
          <Form.Control type="text" name="otherlang" id="js-form-otherlang" />
        </Form.Group>
        <Form.Group id="formUseCountry">
          <Form.Label htmlFor="js-form-usecountry">{t("contact-usecountry")}</Form.Label>
          <Form.Control type="text" name="usecountry" id="js-form-usecountry" />
        </Form.Group>
        <Form.Group id="formInternet">
          <Form.Label>{t("contact-internet")}</Form.Label>
          <Form.Check custom type={"checkbox"} id={"js-form-internet-yes"}>
            <Form.Check.Input type={"checkbox"} name="internet-yes" id="js-form-internet-yes" />
            <Form.Check.Label htmlFor="js-form-internet-yes">{t("contact-internet-yes")}</Form.Check.Label>
          </Form.Check>
          <Form.Check custom type={"checkbox"} id={"js-form-internet-no"}>
            <Form.Check.Input type={"checkbox"} name="internet-no" id="js-form-internet-no" />
            <Form.Check.Label htmlFor="js-form-internet-no">{t("contact-internet-no")}</Form.Check.Label>
          </Form.Check>
        </Form.Group>
        <Form.Group id="formFundingDetails">
          <Form.Label htmlFor="js-form-fundingdetails" id="formfundingdetailsLabel">{t("contact-fundingdetails")}</Form.Label>
          <Form.Control as="textarea" name="fundingdetails" rows="6" id="js-form-fundingdetails" aria-labelledby="formfundingdetailsLabel"/>
        </Form.Group>
        <Form.Group id="formSpecialNeeds">
          <Form.Label htmlFor="js-form-specialneeds" id="formSpecialNeedsLabel">{t("contact-specialneeds")}</Form.Label>
          <Form.Control as="textarea" name="specialneeds" rows="6" id="js-form-specialneeds" aria-labelledby="formSpecialNeedsLabel"/>
        </Form.Group>
        <Form.Group id="formCouponCode">
          <Form.Label htmlFor="js-form-couponcode" id="cf-couponLabel">{t("contact-couponcode")}</Form.Label>
          <Form.Control type="text" name="couponcode" id="js-form-couponcode" aria-labelledby="cf-couponLabel"/>
        </Form.Group>
        <Form.Group id="formChecks">
          <Form.Check custom type={"checkbox"} id={"js-form-privacy"}>
            <Form.Check.Input type={"checkbox"} required aria-labelledby="cf-privacyLabel"/>
            <Form.Check.Label id="cf-privacyLabel">
              <Trans i18nKey="form-privacy-text">
                <Link to="/legal/privacy" className="link-underline styled-link">{t("privacy")}</Link>
              </Trans>
              <span className="cf-required"> ({t("form-required")})</span>
            </Form.Check.Label>
          </Form.Check>
          <Form.Check custom type={"checkbox"} id={"js-form-mails"} label={t("form-email-text")} name="mails" />
        </Form.Group>
        <Form.Label id="captchaLabel">{t("captcha-message")} <span className="cf-required">({t("form-required")})</span></Form.Label>
        <Turnstile siteKey='0x4AAAAAAAEeonqE2x7v_tXe' options={{language: getLanguage()}} />
        <input type="hidden" name="location" value={window.location.href} />
        <input type="hidden" name="language" value={getLanguage()} />
        <Button variant="primary" type="submit" name="submit" value="Submit" className="captchaBoundSubmit" id="js-contact-submit-btn">
          {t("form-submit")}
        </Button>
        <Alert variant="danger" id="js-form-errCaptchaFailed" className="js-form-message" hidden>
          {t("form-err-captcha")}
        </Alert>
        <Alert variant="danger" id="js-form-errPrivacy" className="js-form-message" hidden>
          {t("form-err-privacy")}
        </Alert>
        <Alert variant="danger" id="js-form-errServerFailed" className="js-form-message" hidden>
          {t("form-err-unknown")}
        </Alert>
        <Alert variant="success" id="js-form-success" className="js-form-message" hidden>
          {t("form-success")}
        </Alert>
      </Form>
    </Container>
  );
}

function cfHandleSubmit(event) {
  event.preventDefault();
  let oldText = document.getElementById("js-contact-submit-btn").innerHTML;
  let divs = document.getElementsByClassName("js-form-message")
  for (var i = 0; i < divs.length; i++) {
    divs[i].setAttribute("hidden", "1");
  }
  document.getElementById("js-contact-submit-btn").innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';
  const data = new FormData(event.target);

  if (!document.getElementById("js-form-privacy").checked) {
    document.getElementById("js-form-errPrivacy").removeAttribute("hidden");
    document.getElementById("js-contact-submit-btn").innerHTML = oldText;
  } else {
    fetch("/form/submit.php", {
      method: 'POST',
      body: data,
    }).then(res => {
      res.text().then(function (text) { // whyever in hell getting the text from a response has to be a promise
        if (text.includes("sent")) document.getElementById("js-form-success").removeAttribute("hidden");
        else if (text.includes("failed")) document.getElementById("js-form-errCaptchaFailed").removeAttribute("hidden");
        else document.getElementById("js-form-errServerFailed").removeAttribute("hidden");
        document.getElementById("js-contact-submit-btn").innerHTML = oldText;
      });
    }).catch(e => {
      document.getElementById("js-form-errServerFailed").removeAttribute("hidden");
      document.getElementById("js-contact-submit-btn").innerHTML = oldText;
    });
  }
}