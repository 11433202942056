import React from 'react';
import { useTranslation } from "react-i18next";
import { Container, Jumbotron, Button } from 'react-bootstrap';


export function ContactSection() {
  const { t } = useTranslation();
  return (
    <Jumbotron fluid className="pageSection contact-section">
      <Container>
        <h2 className="not-bold h3">{t("contact-learn-more")}</h2>
        <br />
        <span className="call-number">{t("contact-hotline")} <a href="tel:+43 676 3456 343" className="styled-link">+43 676 3456 343</a></span>
        <br /><br />
        <Button variant="dark" size="lg" href="/contact">{t("contact-cta")}</Button>
      </Container>
    </Jumbotron>
  );
}
