import React from 'react';
import { BrandEndText } from '../components/BrandEndText';
import { ContactForm } from "../components/ContactForm";

export function ContactPage() {
  return <>
    <BrandEndText />
    <ContactForm />
  </>;
}
