import React from 'react';
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Jumbotron } from 'react-bootstrap';
import { KonnektBranding } from "./KonnektBranding";
import { BrandingText } from "./BrandingText";


export function BrandEndText() {
  const { t } = useTranslation();
  return <>
    <Jumbotron fluid className="pageSection">
      <Container>
        <Row>
          <Col><h2 className="text-left"><BrandingText /> {t("videophone")} <b><KonnektBranding /></b></h2></Col>
          <Col><h2 className="text-right">{t("landing-simple-phone")}</h2></Col>
        </Row>
      </Container>
    </Jumbotron>
  </>;
}
