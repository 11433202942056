import React from 'react';
import { useTranslation } from "react-i18next";
import { Container, Button } from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import { stripeAPIKey, getLanguage } from '../App';
import { useParams } from 'react-router-dom';

export function UpdatePaymentMethod() {
  let props = useParams()
  const stripePromise = loadStripe(stripeAPIKey);
  const { t } = useTranslation();

  const handleClick = async (event) => {
    // Call your backend to create the Checkout session.
    let sessionBody = {
      "language": getLanguage(),
      "items": {},
      "subscriptions": {},
      "customer": props.customer,
      "subscription": props.subscription
    };

    fetch("https://bildfon.com/payment-api/createUpdateSession.php", {
      //fetch("https://dev.bildfon.com/payment-api/createUpdateSession.php", { // DEV
      //fetch("http://localhost:8000/payment-api/createUpdateSession.php", { // TEST
      method: 'POST',
      body: JSON.stringify(sessionBody)
    }).then(res => {
      res.json().then(async (obj) => {
        let sessionId = obj.session_id;

        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId,
        });
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
        if (error)
          alert("Ein Fehler ist aufgetreten: " + error.message);
      });
    }).catch(e => {
    });
  };

  return (
    <Container>
      <h1>{t("update-payment-header")}</h1>
      <br />

      <Button role="link" onClick={handleClick}>
        {t("update-payment-submit")}
      </Button>

    </Container>
  );
}
