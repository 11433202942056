import React from 'react';
import { useTranslation } from "react-i18next";
import { Container } from 'react-bootstrap';
import { Link /*, useLocation, useHistory*/ } from "react-router-dom";

export function CheckoutCancel() {
  const { t } = useTranslation();
  return (
    <Container className="text-center align-middle centerTextPageContainer">
      <div className="notFoundInner">
        <h1>{t("checkout-cancel-head")}</h1>
        <h3 className="not-bold">{t("checkout-cancel-text")}</h3>
        <Link to="/">{t("checkout-cancel-return")}</Link>
      </div>
    </Container>);
}
