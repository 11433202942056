import React from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Container, Button } from 'react-bootstrap';


export function ShopProductDetailSection(props) {
  const { t } = useTranslation();
  return <Container>
    <h2 class="h3">{t("shop-heading-information")}</h2>
    <p><Trans i18nKey={`product-${props.product}-info`}></Trans></p>
    <Button href="/" target="new">{t("shop-more-information")}</Button>
    <br /><br />
    <h2 class="h3">{t("shop-heading-commissioning")}</h2>
    <p><Trans i18nKey={`product-${props.product}-commissioning`}></Trans></p>
    <h2 class="h3">{t("shop-heading-specs")}</h2>
    <ul>{t(`product-${props.product}-specs`).split("\n").map(item => { return <li>{item}</li>; })}</ul>

  </Container>;
}
