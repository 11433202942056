import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import './App.css';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import translationEn from './translations/en';
import translationDe from './translations/de';
import translationFr from './translations/fr';
import translationNl from './translations/nl';
import translationEs from './translations/es';
import translationIt from './translations/it';
import translationEnLegal from './translations/en_legal';
import translationDeLegal from './translations/de_legal';
import translationFrLegal from './translations/fr_legal';
import translationNlLegal from './translations/nl_legal';
import translationEsLegal from './translations/es_legal';
import translationItLegal from './translations/it_legal';
import prices from './prices';
import ReactGA from 'react-ga';
import { Home } from './pages/Home';
import { ShopHome } from './pages/ShopHome';
import { ShopCart } from './pages/ShopCart';
import { CheckoutSuccess } from './pages/CheckoutSuccess';
import { CheckoutCancel } from './pages/CheckoutCancel';
import { RegionSelector } from './pages/RegionSelector';
import { ShopProduct } from './pages/ShopProduct';
import { ContactPage } from './pages/ContactPage';
import { Footer } from './components/Footer';
import { NotFound } from './pages/NotFound';
import { LegalText } from './pages/LegalText';
import { LegalHub } from './pages/LegalHub';
import { MppPage } from './pages/MppPage';
import { UpdatePaymentMethod } from './pages/UpdatePaymentMethod';
ReactGA.initialize('UA-165036422-2');
ReactGA.ga('set', 'anonymizeIp', true); // anonymize the IP to not have to add an annoying cookie warning
ReactGA.pageview(window.location.pathname + window.location.search);
export const stripeAPIKey = 'pk_live_ifaZ2MhIBmdA8u3xIygsmHXQ00Ac1rImcw';
//export const stripeAPIKey = 'pk_test_BfhS3TS2xcclnoIkPUeI3fdn004XEbJ6YT';

// i18n
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // detect user language on first load
  .init({
    resources: {
      en: {
        translation: translationEn,
        legal: translationEnLegal
      },
      de: {
        translation: translationDe,
        legal: translationDeLegal
      },
      fr: {
        translation: translationFr,
        legal: translationFrLegal
      },
      nl: {
        translation: translationNl,
        legal: translationNlLegal
      },
      es: {
        translation: translationEs,
        legal: translationEsLegal
      },
      it: {
        translation: translationIt,
        legal: translationItLegal
      }
    },
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true
    },
    detection: {
      lookupQuerystring: 'language'
    }
  });

export const products = {
  "device": {
    "shipping": true,
    "contentId": "device",
    "subscription": false,
    "apiId": "device",
    "required": "service_monthly",
    "image": `/assets/device-lang.png` // lang is replaced with i18n.language (de, en)
  },
  "service_monthly": {
    "shipping": false,
    "contentId": "service_monthly",
    "subscription": true,
    "subscription_period": "month",
    "apiId": "service-monthly",
    "image": "/assets/looking-at.png"
  }
}
// Translate asset
export const assetLanguageMap = { "en": 1, "de": 1, "it": 1, "fr": 1, "nl": 1 }; // Supported asset translations

function setLanguage(lang) {
  i18n.changeLanguage(lang);
}

export function getLanguage() { // Helper method to save from dumb de-DE
  if (i18n.language.includes("-")) return i18n.language.substring(0, 2).toLowerCase();
  else return i18n.language.toLowerCase();
}

function App() {
  const { t } = useTranslation();
  return (
    <Router>
      <div className="App">

        <Navbar bg="light" expand="md" sticky="top">
          <Navbar.Brand><Link to="/"><img alt={t("logo-alt")} src="/assets/bildfon-konnekt.png" height="50" className="d-inline-block align-top" />{' '}</Link></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {/*<Nav.Link href="/shop/region">{t("nav-shop")}</Nav.Link>*/}
              <NavDropdown title="Language" id="lang-dropdown">
                <NavDropdown.Item onClick={() => { setLanguage("en") }} href="?language=en">english</NavDropdown.Item>
                <NavDropdown.Item onClick={() => { setLanguage("de") }} href="?language=de">deutsch</NavDropdown.Item>
                <NavDropdown.Item onClick={() => { setLanguage("fr") }} href="?language=fr">francais</NavDropdown.Item>
                <NavDropdown.Item onClick={() => { setLanguage("nl") }} href="?language=nl">nederlands</NavDropdown.Item>
                <NavDropdown.Item onClick={() => { setLanguage("es") }} href="?language=es">espanol</NavDropdown.Item>
                <NavDropdown.Item onClick={() => { setLanguage("it") }} href="?language=it">italiano</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              {/*<Nav.Link href="/shop/cart">{t("cart")}</Nav.Link>*/}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <div className="page">
          <Routes>
            <Route path="/" element={<Home/>}></Route>
            <Route path="contact" element={<ContactPage />}/>
            <Route path="maxproplus" element={<MppPage />}/>
            <Route path="maxpro+" element={<MppPage />}/>
            <Route path="shop" element={<RegionSelector />}/>
            <Route path="shop/region" element={<RegionSelector />}/>
            <Route path="shop/cart" element={<ShopCart />}/>
            <Route path="shop/:region" element={<ShopHome/>}/>
            <Route path="shop/:region/:product" element={<ShopProduct/>}/>
            <Route path="legal" element={<LegalHub />}/>
            <Route path="checkout/success" element={<CheckoutSuccess />}/>
            <Route path="checkout/cancel" element={<CheckoutCancel />}/>
            <Route path="legal/:document" element={<LegalText />}/>
            <Route path="legal/:document/:lang"  element={<LegalText />}/>
            <Route path="updatePaymentMethod/:customer/:subscription" element={<UpdatePaymentMethod/>}/>
            <Route path="*" element={<NotFound/>}/> {/* 404 */}
          </Routes>
        </div>

      </div>
      <Footer />
    </Router>
  );
}

export function removeCoupon() {
  localStorage.removeItem("coupon");
  refreshCart();
}

export function getCartTax() {
  let region = localStorage.getItem("region").toLowerCase()
  let items = JSON.parse(localStorage.getItem("cart-items"))
  let sum = 0;
  for (let key in items) {
    sum += prices[region][key] * items[key]
  }
  sum += cartShipping()
  let tax = prices[localStorage.getItem("region").toLowerCase()]["vat"] || 0
  return ((sum / (100 + tax)) * tax).toFixed(2);
}

export function getCartTaxSumNow() {
  return getCartNetSumNow()
}
export function getCartTaxSumRec() {
  return getCartNetSumRec()
}

function getCartNetSumNow() {
  let coupon = JSON.parse(localStorage.getItem("coupon"))
  let region = localStorage.getItem("region").toLowerCase()
  let items = JSON.parse(localStorage.getItem("cart-items"))
  let sum = 0;
  for (let key in items) {
    if (!products[key].subscription) sum += prices[region][key] * items[key]
  }
  sum += cartShipping()
  if (coupon) sum -= (coupon.value / 100)

  return sum.toFixed(2);
}

function getCartNetSumRec() {
  let region = localStorage.getItem("region").toLowerCase()
  let items = JSON.parse(localStorage.getItem("cart-items"))
  let sum = 0;
  for (let key in items) {
    if (products[key].subscription) sum += prices[region][key] * items[key]
  }
  return sum.toFixed(2);
}

export function cartShipping() {
  let items = JSON.parse(localStorage.getItem("cart-items"))
  let ship = false;
  for (let key in Object.keys(items)) {
    console.log(Object.keys(items)[key] + ": " + products[Object.keys(items)[key]].shipping)
    if (items[Object.keys(items)[key]] > 0 && products[Object.keys(items)[key]].shipping) ship = true;
  }
  return ship ? prices[localStorage.getItem("region").toLowerCase()]["shipping"] || 0 : 0;
}

export function cartEditAmount(key) {
  let region = localStorage.getItem("region").toLowerCase()
  let items = JSON.parse(localStorage.getItem("cart-items"))
  let productName = key.target.id.substring("cart-count-".length)
  items[productName] = Number(key.target.value)
  localStorage.setItem("cart-items", JSON.stringify(items))
  document.getElementById("cart-product-total-" + productName).textContent = (prices[region][productName] * key.target.value) + prices[region]["vat"] || 0
  refreshCart();
  if (key.target.value < 1) window.location.reload();
}
export function cartRemove(key) {
  let region = localStorage.getItem("region").toLowerCase()
  let items = JSON.parse(localStorage.getItem("cart-items"))
  let productName = key.target.id.substring("cart-remove-".length)
  items[productName] = Number(0)
  localStorage.setItem("cart-items", JSON.stringify(items))
  document.getElementById("cart-product-total-" + productName).textContent = (prices[region][productName] * items[productName]) + prices[region]["vat"] || 0
  refreshCart();
  window.location.reload();
}

export function addToCart(key, amount) {
  let items = JSON.parse(localStorage.getItem("cart-items"))
  items[key] = amount
  localStorage.setItem("cart-items", JSON.stringify(items))

  if (products[key].required) {

  }
}

export function switchRegion() {
  localStorage.removeItem("coupon"); // Remove coupons just in case they are region locked
  localStorage.setItem("region", document.getElementById("countrySwitcher").value);
  //refreshCart();
  window.location.reload();
}

function refreshCart() {
  /*document.getElementById("cart-tax-percentage").textContent = document.getElementById("countrySwitcher").value + ": " + (prices[region]["vat"] || 0) + "%"
  document.getElementById("cart-shipping").textContent = cartShipping()
  document.getElementById("cart-tax-sum").textContent = getCartTax()
  document.getElementById("cart-sum-now").textContent = getCartTaxSumNow()
  document.getElementById("cart-sum-rec").textContent = getCartTaxSumRec()*/
  window.location.reload();
}

export default App;
