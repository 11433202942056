import React from 'react';
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from 'react-bootstrap';
import { Link /*, useLocation, useHistory*/ } from "react-router-dom";
import prices from '../prices';
import { prefillItemList } from '../util/productUtils'

export function RegionSelector() {
  const { t } = useTranslation();
  prefillItemList();
  return (
    <Container fluid="sm">
      <Row>
        <Col md={{ span: 6, offset: 3 }} align="center"><h1>{t("region-cta")}</h1></Col>
      </Row>
      <Row sm="2">
        {Object.keys(prices).sort().map((item) => (
          <Col md="4" align="center">
            <Link to={"/shop/" + item.toLowerCase()} className="styled-link">
              <div>
                <img src={"../assets/flags/" + item + ".svg"} alt={t(prices[item].name)} className="region-flag" aria-hidden="true"></img><br />
                <h3 className="region-text">{item.toUpperCase()} {t(prices[item.toLowerCase()].name)}</h3>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>);
}
