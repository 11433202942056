import React, { useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col, Jumbotron, Button, Form, Modal } from 'react-bootstrap';
import { Link /*, useLocation, useHistory*/, Navigate, useParams } from "react-router-dom";
import prices from '../prices';
import { products, getLanguage, assetLanguageMap, addToCart } from '../App';
import { prefillItemList } from '../util/productUtils'
import { getPrice, getSubscriptionPeriod } from "../util/productUtils";
import { ShopProductDetail } from "./ShopProductDetail";

export function ShopProduct() {
  let props = useParams();
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  prefillItemList();
  // Check for valid product
  if (!products[props.product])
    return (<Navigate to={"/shop/" + props.region} replace />);
  let product = products[props.product];
  let deviceAsset;
  const assetLanguage = getLanguage();
  if (assetLanguageMap[assetLanguage])
    deviceAsset = <img className="shopCardImg" src={"/assets/device-" + assetLanguage + ".png"} alt={t(`product-${product.contentId}-name`)} />;
  else
    deviceAsset = <img className="shopCardImg" src={"/assets/device-en.png"} alt={t(`product-${product.contentId}-name`)} />; // Default to english asset



  // Modal
  let handleClose = () => { setShow(false); window.location.href = "/shop/cart"; };
  let handleShow = () => setShow(true);

  return (
    <div className="page-shop-product">
      <Jumbotron fluid className="pageSection">
        <Container>
          <Row>
            <Col md="6">{product.contentId === "device" ? deviceAsset : <img className="shopCardImg" src={product.image.replace("lang", assetLanguageMap[assetLanguage] ? assetLanguage : "en")} alt={t(`product-${product.contentId}-name`)} />}</Col>
            <Col md="6">
              <h1 class="h2">{t(`product-${product.contentId}-name`)}</h1>
              <span className={getPrice(product.contentId + "_old", props.region) ? "price-deal" : "price"}>€ {getPrice(product.contentId, props.region)} </span>
              {getPrice(product.contentId + "_old", props.region) ? <span className="price-old">€ {getPrice(product.contentId + "_old", props.region)}</span> : <span />}
              <br />
              {product.subscription ? getSubscriptionPeriod(product.subscription_period, t) : (<span />)}
              <span>{t("store-with-vat", { vat: prices[props.region.toLowerCase()]["vat"] })}</span>
              {product.shipping ?
                (prices[props.region.toLowerCase()]["shipping"] > 0 ?
                  (<span>, <Link to="/legal/delivery-terms" className="styled-link link-underline">{t("store-excl-delivery", { price: prices[props.region.toLowerCase()]["shipping"] })}</Link></span>) :
                  (<span>, <Link to="/legal/delivery-terms" className="styled-link link-underline">{t("store-incl-delivery")}</Link></span>))
                : (<></>)}
              <br />
              <span>{product.shipping ? t('shipping-to-region', { region: t(prices[props.region.toLowerCase()].name) }) : <></>}</span>
              <Form.Control as="input" type="number" min="1" max="10" id={"js-product-count-" + product.contentId} defaultValue={localStorage.getItem("cart-items") ? JSON.parse(localStorage.getItem("cart-items"))[product.contentId] || 1 : 1} />
              <p>
                <Trans i18nKey={`product-${product.contentId}-description-long`}></Trans>
              </p>
              <Button onClick={() => { addToCart(product.contentId, document.getElementById("js-product-count-" + product.contentId).value); localStorage.setItem("region", props.region.toLowerCase()); handleShow(); }}>{t("add-to-cart")}</Button>
            </Col>
          </Row>
        </Container>
      </Jumbotron>

      <ShopProductDetail product={props.product} />

      {<Modal show={show} centered onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("cart-added", { name: t(`product-${product.contentId}-name`) })}</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          {<Button variant="secondary" onClick={handleClose}>
            {t("continue-to-cart")}
          </Button>}
        </Modal.Footer>
      </Modal>}
    </div>
  );
}
