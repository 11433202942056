import React from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Container } from 'react-bootstrap';
import { ShopProductDetailSection } from "../components/ShopProductDetailSection";


export function ShopProductDetail(props) {
  const { t } = useTranslation();
  switch (props.product) {
    case "device":
      return <ShopProductDetailSection product={props.product}></ShopProductDetailSection>;
    default: return <Container><h3>{t("shop-heading-information")}</h3><Trans i18nKey={t(`product-${props.product}-info`)}></Trans></Container>;
  }
}
