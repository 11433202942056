import React from 'react';
import { useTranslation } from "react-i18next";
import { Container, Jumbotron } from 'react-bootstrap';
import { Link } from "react-router-dom";

export function Footer() {
  const { t } = useTranslation();
  return (
    <Jumbotron fluid className="footer">
      <Container>
        <Link to="/legal/imprint" className="footer-link">{t("imprint")}</Link>
        <span className="footer-separator">|</span>
        <Link to="/legal/privacy" className="footer-link">{t("privacy")}</Link>
        <span className="footer-separator">|</span>
        <Link to="/legal/cookie-policy" className="footer-link">{t("cookie-policy")}</Link>
        <span className="footer-separator">|</span>
        <Link to="/legal/right-of-withdrawal" className="footer-link">{t("right-of-withdrawal")}</Link>
        <span className="footer-separator">|</span>
        <Link to="/legal/terms-conditions" className="footer-link">{t("terms-conditions")}</Link>
        <span className="footer-separator">|</span>
        <Link to="/legal/delivery-terms" className="footer-link">{t("delivery-terms")}</Link>
      </Container>
    </Jumbotron>
  );
}
