import React from 'react';
import { useTranslation } from "react-i18next";
import { Container } from 'react-bootstrap';

export function LegalHub() {
  const { t } = useTranslation();
  return (
    <Container className="text-center align-middle">
      <div className="notFoundInner">
        <h3 className="not-bold">{t("legal:legalhub-text")}</h3>
      </div>
    </Container>
  );
}
