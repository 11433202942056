import React from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Container } from 'react-bootstrap';
import { Link, useParams } from "react-router-dom";

export function LegalText() {
  let props = useParams();
  const { t } = useTranslation();

  let ret;
  if (props.document === "imprint")
    ret = (<Container><h1 id="top">{t("imprint", { lng: props.lang })}</h1><br /><p><Trans i18nKey="legal:imprint" tOptions={{ lng: props.lang }}></Trans></p><Link to="/legal/imprint/en" className="styled-link link-underline">{t("legal:doubt", { lng: props.lang })}</Link></Container>);
  else if (props.document === "privacy")
    ret = (<Container><h1 id="top">{t("privacy", { lng: props.lang })}</h1><br /><p><Trans i18nKey="legal:privacy" tOptions={{ lng: props.lang }}></Trans></p><Link to="/legal/privacy/en" className="styled-link link-underline">{t("legal:doubt", { lng: props.lang })}</Link></Container>);
  else if (props.document === "cookie-policy")
    ret = (<Container><h1 id="top">{t("cookie-policy", { lng: props.lang })}</h1><br /><p><Trans i18nKey="legal:cookie-policy" tOptions={{ lng: props.lang }}></Trans></p><Link to="/legal/cookie-policy/en" className="styled-link link-underline">{t("legal:doubt", { lng: props.lang })}</Link></Container>);
  else if (props.document === "right-of-withdrawal")
    ret = (<Container><h1 id="top">{t("right-of-withdrawal", { lng: props.lang })}</h1><br /><p><Trans i18nKey="legal:right-of-withdrawal" tOptions={{ lng: props.lang }}></Trans></p><Link to="/legal/right-of-withdrawal/en" className="styled-link link-underline">{t("legal:doubt", { lng: props.lang })}</Link></Container>);
  else if (props.document === "terms-conditions")
    ret = (<Container><h1 id="top">{t("terms-conditions", { lng: props.lang })}</h1><br /><p><Trans i18nKey="legal:terms-conditions" tOptions={{ lng: props.lang }}></Trans></p><Link to="/legal/terms-conditions/en" className="styled-link link-underline">{t("legal:doubt", { lng: props.lang })}</Link></Container>);
  else if (props.document === "delivery-terms")
    ret = (<Container><h1 id="top">{t("delivery-terms", { lng: props.lang })}</h1><br /><p><Trans i18nKey="legal:delivery-terms" tOptions={{ lng: props.lang }}></Trans></p><Link to="/legal/delivery-terms/en" className="styled-link link-underline">{t("legal:doubt", { lng: props.lang })}</Link></Container>);
  else {
    window.location.href = "../";
    return;
  }
  document.getElementById("root").scrollIntoView();
  return ret;
}
