import React from 'react';
import { useTranslation } from "react-i18next";
import { Container } from 'react-bootstrap';
import { Link } from "react-router-dom";

export function NotFound() {
  const { t } = useTranslation();
  return (
    <Container className="text-center align-middle notFoundContainer">
      <div className="notFoundInner">
        <h1>{t("notfound-head")}</h1>
        <h3 className="not-bold">{t("notfound-text")}</h3>
        <Link to="/">{t("notfound-return")}</Link>
      </div>
    </Container>
  );
}
