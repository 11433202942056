import React from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Container } from 'react-bootstrap';
import { Link /*, useLocation, useHistory*/ } from "react-router-dom";

export function CheckoutSuccess() {
  const { t } = useTranslation();
  localStorage.removeItem("coupon");
  localStorage.removeItem("cart-items");
  return (
    <Container className="text-center align-middle centerTextPageContainer">
      <div className="notFoundInner">
        <h1>{t("checkout-success-head")}</h1>
        <h3 className="not-bold">{t("checkout-success-text")}</h3>
        <h3 className="not-bold"><Trans i18nKey="checkout-support-text"><a href="mailto:support@bildfon.com">support@bildfon.com</a></Trans></h3>
        <Link to="/">{t("checkout-success-return")}</Link>
      </div>
    </Container>);
}
