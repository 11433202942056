import i18next from 'i18next';
import { getLanguage } from '../App';


export function handleCouponSubmit() {
  const t = i18next.t.bind(i18next);
  let code = document.getElementById("js-coupon-input").value;

  fetch("https://bildfon.com/payment-api/validateCoupon.php", {
    //fetch("https://dev.bildfon.com/payment-api/validateCoupon.php", { // DEV
    //fetch("http://localhost:8000/payment-api/validateCoupon.php", { // TEST
    method: 'POST',
    body: JSON.stringify({ "code": code, "region": localStorage.getItem("region").toLowerCase() })
  }).then(res => {
    res.json().then(async (obj) => {
      if (obj.valid) {
        if (obj.country && obj.country.toLowerCase() !== localStorage.getItem("region").toLowerCase()) {
          alert(t("coupon-code-invalid"));
          return;
        }
        console.log(`Used coupon code ${obj.name[getLanguage().toLowerCase()]} worth ${obj.value / 100}€`);
        localStorage.setItem("coupon", JSON.stringify(obj));
        console.log(obj);
        alert(t("coupon-code-valid", { name: obj.name[getLanguage().toLowerCase()], value: obj.hideValue ? '' : obj.value / 100 + "€" }));
        window.location.reload();
      }
      else {
        alert(t("coupon-code-invalid"));
      }
    });
  }).catch(e => {
  });
}
