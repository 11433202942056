import React from 'react';
import { Trans } from "react-i18next";
import { Button, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import prices from '../prices';
import { products, assetLanguageMap, getLanguage, cartEditAmount, cartRemove, cartShipping, switchRegion, getCartTax, removeCoupon, getCartTaxSumNow, getCartTaxSumRec } from '../App';


export function CartItemTable(t) {
  let items = JSON.parse(localStorage.getItem("cart-items"));
  let coupon = JSON.parse(localStorage.getItem("coupon"));
  let region = localStorage.getItem("region").toLowerCase();
  let rows = [];
  for (let key in items) {
    if (!items[key] || items[key] < 1) { }
    else
      rows.push(
        <tr>
          <td><Link to={`/shop/${region}/${products[key].contentId}`} aria-hidden="true"><img src={products[key].image.replace("lang", assetLanguageMap[getLanguage()] ? getLanguage() : "en")} alt="" className="cart-img" /></Link></td>
          <td><Form.Control type="number" min="0" id={"cart-count-" + key} defaultValue={items[key]} onChange={cartEditAmount} className="cart-amount-selector" title={t("cart-count")}></Form.Control> <Trans i18nKey={"product-" + key + "-name"} /> {(products[key].subscription && items["device"] > 0) ? <p>{t("first-month-free").toUpperCase()}</p> : <></>}</td>
          <td id={"cart-product-total-" + key} className="priceCol">{(prices[region][key] * items[key]).toFixed(2)}</td>
          <td><Button variant="outline-danger" id={"cart-remove-" + key} onClick={cartRemove}>{t("cart-remove")}</Button></td>
        </tr>
      );
  }
  if (cartShipping())
    rows.push(<tr className="cart-ship-row"><td colSpan="2"><Trans i18nKey={"cart-shipping"} /></td><td id="cart-shipping" className="priceCol">{cartShipping().toFixed(2)}</td></tr>);
  rows.push(
    <tr className="cart-tax-row">
      <td>
        <Form.Label htmlFor="countrySwitcher"><Trans i18nKey={"cart-region"} />:</Form.Label>
        <Form.Control as="select" id="countrySwitcher" defaultValue={localStorage.getItem("region").toUpperCase()} onChange={switchRegion}>
          {Object.keys(prices).map((item) => (
            <option>{item.toUpperCase()}</option>
          ))}
        </Form.Control>
      </td>
      <td><Trans i18nKey={"cart-tax"} /><br /><span id="cart-tax-percentage">{localStorage.getItem("region").toUpperCase() + ": " + (prices[localStorage.getItem("region").toLowerCase()]["vat"] || "0") + "%"}</span></td>
      <td id="cart-tax-sum" className="priceCol">{getCartTax()}</td>
    </tr>);
  if (coupon)
    rows.push(<tr><td colSpan="2">{t("cart-using-coupon") + " " + coupon.name[getLanguage().toLowerCase()]}</td><td className="priceCol">{"-" + (coupon.value / 100).toFixed(2)}</td><td><Button variant="outline-danger" onClick={removeCoupon}>{t("cart-remove")}</Button></td></tr>);
  rows.push(<tr className="cart-sum-row"><td colSpan="2"><Trans i18nKey={"cart-total-now"} /></td><td id="cart-sum-now" className="priceCol">{getCartTaxSumNow()}</td></tr>);
  rows.push(getCartTaxSumRec() > 0 ? <tr className="cart-sum-row"><td colSpan="2"><Trans i18nKey={"cart-total-recurring"} /></td><td id="cart-sum-rec" className="priceCol">{getCartTaxSumRec()}</td></tr> : <></>);
  return rows;
}
