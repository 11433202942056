import React from 'react';
//import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col, Jumbotron, Carousel } from 'react-bootstrap';
//import { Link /*, useLocation, useHistory*/ } from "react-router-dom";
//import { getLanguage } from '../App.js';
import { ContactForm } from "../components/ContactForm";
import { ContactSection } from "../components/ContactSection";
import { BrandingText } from "../components/BrandingText";
import { MppBrandingText } from '../components/MppBrandingText.js';
import { MppDemo } from '../components/MppDemo.js';

export function MppPage() {
  //const { t } = useTranslation();
  return (
    <div className="page-mpp-landing">
      <Container>
        <Container>
          <Row>
            <h1 className="not-bold">Bildtelefon <BrandingText /> <MppBrandingText /></h1>
            <span className="mpp-subhead">
              <p><span className="bf-red">max</span>imal barrierefrei videotelefonieren<br />
                <span className="bf-red">PRO</span>fessionell und sicher in die Unternehmens-Kommunikation integrierbar<br />
                <span className="bf-red"><b>+</b></span> kontrolliert Angehörige einbinden</p>
            </span>
          </Row>
        </Container>
        <Jumbotron fluid className="pageSectionMppPoints">
          <Container>
            <Row className="mpp-feature-top">
              <Col>
                <span>Einfache Bedienung</span>
              </Col>
              <Col>
                <span>Automatische Rufannahme</span>
              </Col>
              <Col>
                <span>DSGVO und HIPAA-konform</span>
              </Col>
            </Row>
          </Container>
        </Jumbotron>
        <Jumbotron fluid className="pageSection pageSectionMppShowcase">
          <Container className="d-none d-xl-block">
            <Row>
              <Col>
                <img src="assets/mpp-25.png" alt="" className="mpp-showcase-img" />
                <span className="mpp-showcase-head"><BrandingText /> <MppBrandingText /> 25</span><br />
                <span className="mpp-showcase-foot">minimaler Platzbedarf</span>
              </Col>
              <Col className="mpp-muted">
                <img src="assets/mpp-30.png" alt="" className="mpp-showcase-img" />
                <span className="mpp-showcase-head"><BrandingText /> <MppBrandingText /> 30</span><br />
                <span className="mpp-showcase-foot">die praktische Größe</span>
              </Col>
              <Col>
                <img src="assets/mpp-40.png" alt="" className="mpp-showcase-img" />
                <span className="mpp-showcase-head"><BrandingText /> <MppBrandingText /> 40</span><br />
                <span className="mpp-showcase-foot">nahezu lebensgroßes Video-Gesichtsbild</span>
              </Col>
            </Row>
          </Container>
          <Container className="d-block d-xl-none">
            <Carousel className="mpp-showcase-carousel">
              <Carousel.Item>
                <img
                  className="d-block mpp-showcase-img-carousel"
                  src="assets/mpp-25.png"
                  alt=""
                />
                <Carousel.Caption className="mpp-showcase-carousel-caption">
                  <span className="mpp-showcase-head"><BrandingText /> <MppBrandingText /> 25</span><br />
                  <span className="mpp-showcase-foot">minimaler Platzbedarf</span>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block mpp-showcase-img-carousel"
                  src="assets/mpp-40.png"
                  alt=""
                />

                <Carousel.Caption className="mpp-showcase-carousel-caption">
                  <span className="mpp-showcase-head"><BrandingText /> <MppBrandingText /> 40</span><br />
                  <span className="mpp-showcase-foot">nahezu lebensgroßes Video-Gesichtsbild</span>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Container>
        </Jumbotron>
        <Container className="mpp-uses-wrap">
          <Row>
            <Col md="6">
              <h2>Für Unternehmen</h2>
              <ul className="mpp-uses">
                <li>DSGVO und HIPAA konforme unternehmensinterne Videokommunikation durch volle Microsoft Teams<sup>1</sup>-Integration</li>
                <li>Für pflegerische, ärztliche und therapeutische Video-Konsultationen geeignet</li>
                <li>Kein Mehraufwand in der Administration, von <BrandingText /> vollständig betreut</li>
              </ul>
            </Col>
            <Col md="6">
              <h2>Für Angehörige</h2>
              <ul className="mpp-uses">
                <li>Freunde und Angehörige können per kostenlosem Skype<sup>2</sup> Videogespräche mit dem Bildtelefon führen</li>
                <li>Einfach administrierbare und sichere Zugänge für unternehmensfremde Kontaktpersonen</li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Container>
          {/*<MppDemo />*/}
        </Container>
        <ContactSection />
        <ContactForm />
        <Container className="mpp-footnotes">
          <ol>
            <li>Unternehmen, die Microsoft Teams bereits nutzen, können die Bildtelefone problemlos als zusätzliche Teams Benutzerkonten administrieren. Alle anderen Unternehmen können sehr einfach eine gesicherte Microsoft Teams Benutzergruppe für die Bildtelefone einrichten. Der bildfon Support steht bei Bedarf für Unterstützung zur Verfügung. Es gelten die Microsoft Teams Nutzungsbedingungen.</li>
            <li>Kostenlose und zeitlich unbegrenzt nutzbare Skype-Benutzerkonten für Bildtelefon-Kontaktpersonen mit speziell vereinfachter Bedienung für maximal einfach zu führende Videogespräche können beliebig eingerichtet und mit dem Bildtelefon gesichert verbunden werden. Für Videogespräche fallen keine Verbindungs-Einrichtungsgebühren und keine Verbindungsentgelte an. Zum Führen von Gesprächen wird eine Internetverbindung benötigt. Datengebühren laut verwendetem Tarif können anfallen. Es gelten die Skype Nutzungsbedingungen.</li>
          </ol>
          Skype, Microsoft Teams und Windows Pro sind Produkte von Microsoft.
        </Container>

      </Container>
    </div>);
}