import React from 'react';
import { useTranslation } from "react-i18next";
import { Container, Button, Form, Table } from 'react-bootstrap';
import { Link /*, useLocation, useHistory*/ } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';
import { stripeAPIKey, getLanguage, products } from '../App';
import { CartItemTable } from "../components/CartItemTable";
import { handleCouponSubmit } from "../util/handleCouponSubmit";

export function ShopCart() {
  const stripePromise = loadStripe(stripeAPIKey);
  const { t } = useTranslation();
  let items = JSON.parse(localStorage.getItem("cart-items"));
  let cartEmpty = true;
  if (items)
    Object.keys(items).forEach(i => {
      if (items[i])
        cartEmpty = false;
    });

  const handleClick = async (event) => {
    // Call your backend to create the Checkout session.
    let coupon = JSON.parse(localStorage.getItem("coupon"));
    let items = JSON.parse(localStorage.getItem("cart-items"));
    let sessionBody = {
      "region": localStorage.getItem("region").toLowerCase(),
      "language": getLanguage(),
      "items": {},
      "subscriptions": {}
    };
    Object.keys(items).forEach(i => {
      if (products[i].subscription)
        sessionBody.subscriptions[products[i].apiId] = items[i];
      else
        sessionBody.items[products[i].apiId] = items[i];
    });
    if (coupon) {
      sessionBody.discount = coupon.code;
    }

    fetch("https://bildfon.com/payment-api/createSession.php", {
      //fetch("https://dev.bildfon.com/payment-api/createSession.php", { // DEV
      //fetch("http://localhost:8000/payment-api/createSession.php", { // TEST
      method: 'POST',
      body: JSON.stringify(sessionBody)
    }).then(res => {
      res.json().then(async (obj) => {
        let sessionId = obj.session_id;

        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId,
        });
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
        if (error)
          alert("Ein Fehler ist aufgetreten: " + error.message);
      });
    }).catch(e => {
    });
  };

  if (cartEmpty) {
    let region = localStorage.getItem("region");
    return (
      <Container className="text-center align-middle centerTextPageContainer">
        <div className="notFoundInner">
          <h1>{t("cart-empty")}</h1>
          <h3 className="not-bold">{t("cart-empty-text")}</h3>
          <h3 className="not-bold"><Link to={region ? "/shop/" + region.toLowerCase() : "/shop"}>{t("cart-empty-link")}</Link></h3>
        </div>
      </Container>);
  }

  return (
    <Container>
      <h1>{t("cart")}</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th><span></span></th>
            <th>{t("cart-product-name")}</th>
            <th className="priceCol">{t("cart-price")}</th>
            <th>{t("cart-options")}</th>
          </tr>
        </thead>
        <tbody>
          {CartItemTable(t)}
        </tbody>
      </Table>

      <Form>
        <Form.Label id="couponInputLabel">{t("enter-coupon-label")}</Form.Label>
        <Form.Control type="text" name="code" id="js-coupon-input" required aria-labelledby="couponInputLabel" />
        <Button variant="primary" name="submit" value="Submit" id="js-coupon-submit" onClick={handleCouponSubmit}>
          {t("check-coupon-btn")}
        </Button>
      </Form>
      <br />

      <Button role="link" onClick={handleClick}>
        {t("cart-checkout")}
      </Button>

    </Container>
  );
}
