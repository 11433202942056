import prices from '../prices';
import React from 'react';


export function getSubscriptionPeriod(subscription_period, t) {
  switch (subscription_period) {
    case "day":
      return <span>{t("store-sub-daily")}, </span>;
    case "month":
      return <span>{t("store-sub-monthly")}, </span>;
    case "year":
      return <span>{t("store-sub-yearly")}, </span>;
    default:
      return <span>{t("store-sub-monthly")}, </span>;
  }
}

export function getPrice(contentId, region) {
  return prices[region][contentId] % 1 === 0 ? prices[region][contentId] : prices[region][contentId].toFixed(2);
}

export function prefillItemList() {
  if (localStorage.getItem("cart-items")) return;
  localStorage.setItem("cart-items", JSON.stringify({ "device": null, "service_monthly": null }))
}