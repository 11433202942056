import React from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col, Jumbotron, Button } from 'react-bootstrap';
import { Link /*, useLocation, useHistory*/ } from "react-router-dom";
import { getLanguage } from '../App.js';
import { ContactForm } from "../components/ContactForm";
import { ContactSection } from "../components/ContactSection";
import { KonnektBranding } from "../components/KonnektBranding";
import { BrandingText } from "../components/BrandingText";
import { BrandEndText } from "../components/BrandEndText";

export function Home() {
  const { t } = useTranslation();
  let assetLanguageMap = { "en": 1, "de": 1, "it": 1, "fr": 1, "nl": 1 }; // Supported asset translations
  let deviceAsset;
  const assetLanguage = getLanguage();
  if (assetLanguageMap[assetLanguage])
    deviceAsset = <img className="bannerImg" src={"/assets/device-" + assetLanguage + ".png"} alt={t("landing-img-alt")} />;
  else
    deviceAsset = <img className="bannerImg" src={"/assets/device-en.png"} alt={t("landing-img-alt")} />; // Default to english asset
  return (
    <div className="page-home">
      <Jumbotron fluid className="pageSection">
        <Container>
          <Row>
            <Col md="6" className="align-self-center">
              <h1><BrandingText /> {t("videophone")} <b><KonnektBranding /></b></h1>
              <br />
              <h2>{t("landing-simple-phone")}</h2>
              <br />
              <p className="section-sub no-pad-end">
                <Trans i18nKey="landing-product-summary"></Trans>
              </p>
              <p className="section-sub skype-section">
                <Trans i18nKey="skype-disclaimer"></Trans>
              </p>
              <br />
              <Link to="/contact"><Button variant="dark" size="lg">{t("contact-cta")}</Button></Link>
            </Col>
            <Col>
              <img className="bannerImg topBannerImg" src="/assets/looking-at.png" alt={t("landing-img-alt")} />
            </Col>
          </Row>
        </Container>
        <br />
        <Container>
          <Row>
            <Col md="6" className="align-self-center">
              <h2><BrandingText /> {t("newsroom-header")}</h2>
              <br />
              <a className="newsroom-article-title" href="https://www.w24.at/News/2021/5/Bildfon-Technologie-gegen-Einsamkeit" target="_blank" rel="noopener noreferrer nofollow">{"W24: \"Bildfon\": Technologie gegen Einsamkeit"}</a>
            </Col>
            <Col>
              <img className="bannerImg topBannerImg" src="/assets/w24-headerimg.jpg" alt="" />
            </Col>
          </Row>
        </Container>
        <br />
        <Container>
          <h2>{t('landing-video-customerssaying')}</h2>
          {VideoRecommendations(getLanguage())}
        </Container>
      </Jumbotron>
      <Jumbotron fluid className="pageSectionAccent">
        <Container>
          <Row>
            <Col>
              {deviceAsset}
            </Col>
            <Col md="6" className="align-self-center">
              <div className="modes-list">
                <p><b>{t("stay-active-telephone")}</b></p>
                <ul>
                  <li>{t("landing-feature1")}</li>
                  <li>{t("landing-feature2")}</li>
                  <li>{t("landing-feature3")}</li>
                  <li>{t("landing-feature4")}</li>
                  <li>{t("landing-feature5")}</li>
                  <li>{t("landing-feature6")}</li>
                </ul>
                <p>{t("modes-after")}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Jumbotron fluid className="pageSection">
        <Container>
          <Row className="make-it-flex">
            <Col md="4" className="">
              <img className="featureImg" src="/assets/computer_confused.png" alt={t("feature-skills-alt")} />
              <div className="align-self-start"><h4>{t("feature-skills-head")}</h4></div>
              <p>
                {t("feature-skills-body")}<span> </span>
                <a className="read-more" href={t('feature-skills-url')} target="_blank" rel="noopener noreferrer nofollow">{t("feature-skills-btn")}</a>
              </p>
            </Col>
            <Col md="4" className="">
              <img className="featureImg" src="/assets/calls_voip.png" alt={t("feature-skype-alt")} />
              <div className="align-self-start"><h4>{t("feature-skype-head")}</h4></div>
              <p>
                {t("feature-skype-body")}<span> </span>
                <a className="read-more" href={t('feature-skype-url')} target="_blank" rel="noopener noreferrer nofollow">{t("feature-skype-btn")}</a>
              </p>
            </Col>
            <Col md="4" className="">
              <img className="featureImg" src="/assets/calls_to_phones.png" alt={t("feature-telephone-alt")} />
              <div className="align-self-start"><h4>{t("feature-telephone-head")}</h4></div>
              <p>
                {t("feature-telephone-body")}<span> </span>
                <a className="read-more" href={t('feature-telephone-url')} target="_blank" rel="noopener noreferrer nofollow">{t("feature-telephone-btn")}</a>
              </p>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Jumbotron fluid className="pageSectionAccent">
        <Container>
          <Row>
            <Col md="4" className="">
              <img className="featureImg" src="/assets/happy-couple.png" alt={t("feature-adapt-alt")} />
              <h4>{t("feature-adapt-head")}</h4>
              <p>
                {t("feature-adapt-body")}<span> </span>
                <a className="read-more" href={t('feature-adapt-url')} target="_blank" rel="noopener noreferrer nofollow">{t("feature-adapt-btn")}</a>
              </p>
            </Col>
            <Col md="4" className="">
              <img className="featureImg" src="/assets/no-telemarketer-calls.png" alt={t("feature-telemarketer-alt")} />
              <h4>{t("feature-telemarketer-head")}</h4>
              <p>
                {t("feature-telemarketer-body")}<span> </span>
                <a className="read-more" href={t('feature-telemarketer-url')} target="_blank" rel="noopener noreferrer nofollow">{t("feature-telemarketer-btn")}</a>
              </p>
            </Col>
            <Col md="4" className="">
              <img className="featureImg" src="/assets/no-high-bills.png" alt={t("feature-bills-alt")} />
              <h4>{t("feature-bills-head")}</h4>
              <p>{t("feature-bills-body")}<span> </span>
                <a className="read-more" href={t('feature-bills-url')} target="_blank" rel="noopener noreferrer nofollow">{t("feature-bills-btn")}</a>
              </p>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <BrandEndText />
      <ContactSection />
      <ContactForm />
    </div>);
}

function VideoRecommendations(language) {
  const { t } = useTranslation();

  var availableVideos = { "en": "rDqw2Vy5oqU", "de": "wnQLRDaCOjY" }
  var preferredVideo = availableVideos[language] ?? availableVideos["en"];
  var preferredLanguage = availableVideos[language] ? language : "en";

  return <>
    <Row>
      <Col>
        <span>{t('landing-video-customerssaying-' + preferredLanguage + '-sub')}</span><br />
        <iframe title={t('landing-video-customerssaying') + ' ' + t('landing-video-customerssaying-' + preferredLanguage)} width="540" height="304" src={`https://www.youtube-nocookie.com/embed/${preferredVideo}?rel=0&modestbranding=1&cc_load_policy=1&cc_lang_pref=` + getLanguage()} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </Col>
      {preferredLanguage !== "en" ? // Show english video in addition if preferred video is different, currently only enabled for large screens
        <>
          <br />
          <Col className="d-none d-xl-block">
            <span>{t('landing-video-customerssaying-en-sub')}</span><br />
            <iframe title={t('landing-video-customerssaying') + ' ' + t('landing-video-customerssaying-en')} width="540" height="304" src={`https://www.youtube-nocookie.com/embed/${availableVideos.en}?rel=0&modestbranding=1&cc_load_policy=1&cc_lang_pref=` + getLanguage()} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Col>
        </> : <> {/* (Temp) Recommend german video in addition if no second video*/}
          <br />
          <Col className="d-none d-xl-block">
            <span>{t('landing-video-customerssaying-de-sub')}</span><br />
            <iframe title={t('landing-video-customerssaying') + ' ' + t('landing-video-customerssaying-en')} width="540" height="304" src={`https://www.youtube-nocookie.com/embed/${availableVideos.de}?rel=0&modestbranding=1&cc_load_policy=1&cc_lang_pref=` + getLanguage()} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Col>
        </>}
    </Row>
  </>;
}